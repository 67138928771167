import { navigate, PageRendererProps } from "gatsby";
import * as React from "react";

interface RedirectTemplateProps extends PageRendererProps {
  pageContext: any;
  data: any;
  location: any;
}

export default class RedirectTemplate extends React.Component<RedirectTemplateProps> {
  public render() {
    const queryObject = new URLSearchParams(this.props.location.search);
    const pageParameter = queryObject.get("p");

    if (pageParameter != null) {
      const redirectUrl = this.props.pageContext.redirects[pageParameter];
      if (redirectUrl != undefined) {
        if (typeof window !== "undefined") {
          navigate("/posts/" + redirectUrl);
        }
        return <div></div>;
      }
    }
    if (typeof window !== "undefined") {
      navigate("/");
    }
    return <div></div>;
  }
}
